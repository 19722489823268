<template>
  <auth-content>
  </auth-content>
</template>

<script>

export default {
  name: 'Index',
  data () {
    return {
    }
  }
}
</script>
