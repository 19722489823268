<template>
  <div>

    <div class="text-center">
      <img src="@/assets/images/settings.svg" class="pt-md-4 pb-md-2 lutin mb-3">
      <form v-if="step === 'phone'" @submit.prevent="setPhone" class="form row justify-content-center align-items-center">
        <div class="font-weight-bold mb-3 px-md-5 mx-md-5">
          <el-card-title :title="$t('user.phone_verification.title')" />
        </div>
        <div class="col-md-6 col-lg-6">

          <el-alert type="warning" v-if="formError !== false">
            <span v-html="formError"></span>
          </el-alert>
          <div class="form-group">

            <VuePhoneNumberInput
              ref="userPhoneNumberField"
              v-model="phone"
              :default-country-code="currentLocale"
              :preferred-countries="[currentLocale]"
              :placeholder="$t('user.phone_verification.phone')"
              :translations="$t('common.phone')"
              @update="phoneFieldChanged"
            />
            <button v-if="!loading" :disabled="phone.length < 5" type="submit" class="my-3 w-100 btn btn-success text-white btn-lg">
              {{ $t('user.phone_verification.send_code') }}
            </button>
            <div v-if="loading" class="bg-white p-4">
              <loader />
            </div>
            <div class="text-grey">
              {{ $t('user.phone_verification.explanations') }}
            </div>
          </div>
        </div>
      </form>

      <form v-if="step == 'code'" @submit.prevent="setCode" class="form row justify-content-center align-items-center">
        <div class="font-weight-bold mb-1 px-md-5 mx-md-5">
          <el-card-title :title="$t('user.phone_verification.title_code')" />
        </div>
        <div class="col-md-5 col-lg-5">

          <el-alert type="warning" v-if="formError !== false">
            <span v-html="formError"></span>
          </el-alert>
          <div class="form-group">
            <the-mask
              mask="######"
              id="code"
              required
              v-model="code"
              type="tel"
              class="my-2 form-control form-control-lg"
              placeholder="000000"
              autocomplete="one-time-code"
            ></the-mask>
            <button v-if="!loading" type="submit" class="my-3 w-100 btn btn-success text-white btn-lg">
              {{ $t('user.phone_verification.verify_code') }}
            </button>
            <div v-if="loading" class="bg-white p-4">
              <loader />
            </div>
            <a href="#" class="mt-3 border-bottom text-grey" @click.prevent="goToPhoneStep()">{{ $t('user.phone_verification.no_code') }}</a>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>

<script>

import { USER_UPDATE, AUTH_VALIDATE_PHONE } from '../../store/mutations-types'

import _ from 'lodash'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'PhoneVerificationForm',
  components: {
    VuePhoneNumberInput,
  },
  data () {
    return {
      step: 'phone',
      phoneFormatted: null,
      phoneCountryCode: null,
      phone: '',
      code: '',
      loading: false,
      formError: false
    }
  },
  mounted () {
    if (this.currentUser.phone_number != null) {
      this.phone = this.currentUser.phone_number
    }
  },
  methods: {
    phoneFieldChanged (e) {
      this.phoneFormatted = e.formattedNumber
      this.phoneCountryCode = e.countryCode
      if (this.user && e.formatNational) {
        this.user.phone_number = e.formatNational
      }
    },
    setPhone: function () {
      this.loading = true
      this.formError = false
      this.$store.dispatch('auth/' + USER_UPDATE, {
        user_id: this.$store.getters['auth/getProfile'].id,
        phone_number: this.phoneFormatted,
        phone_force_validation: true,
      }).then(() => {
        this.step = 'code'
        this.loading = false
      })
      .catch((errors) => {
        this.loading = false
        this.formError = ''
        _.toArray(errors.response.data.errors).forEach( (element) => {
          this.formError+= '' + element[0] + '<br/>'
        })
      })
    },
    setCode: function () {
      this.loading = true
      this.formError = false
      const { code } = this
      this.$store.dispatch('auth/' + AUTH_VALIDATE_PHONE, code).then(() => {
        this.notifSuccess(this.$t('form.user.phone_verification_success'))
        this.$router.push('/')
      })
      .catch((errors) => {
        this.loading = false
        this.formError = ''
        _.toArray(errors.response.data.errors).forEach( (element) => {
          this.formError+= '' + element[0] + '<br/>'
        })
      })
    },
    goToPhoneStep: function () {
      this.formError = false
      this.step = 'phone'
      this.code = ''
    },
    phoneInputFocus: function () {
      if (this.phone == null || this.phone == '') {
        this.phone = this.prefix + ' '
      }
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    currentLocale () {
      return this.$i18n.locale.split('-')[0].toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.lutin {
  max-width: 220px;
}
#code {
  font-size: 2.2em;
  width: 4.6em;
  margin-left: auto;
  margin-right: auto;
}
</style>
