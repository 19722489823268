<template>
  <auth-content>
    <el-header slot="header" :favorites="false" />

    <el-alert type="warning">
      {{ $t('error.401_text') }}
    </el-alert>

  </auth-content>
</template>

<script>

export default {
  name: 'Error401',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
